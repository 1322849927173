import React from 'react'
import { INFO_THEME_WHITE } from '../components/infoblock'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { withPrefix } from 'gatsby'
import { StBold } from '../components/elements/bold'

const coseeIconPath = withPrefix('/logo/cosee.png')
const bitexpertIconPath = withPrefix('/logo/Bitexpert.png')
const dpunktIconPath = withPrefix('/logo/dpunkt.png')
const title = `Unsere Sponsoren`

const StImage = styled.img`
  max-width: 240px;
`
const StRow = styled(Row)`
  margin-bottom: 3rem;
`

const sponsorIconGrid = {
  xs: 12,
  xsOffset: 0,
  sm: 6,
  smOffset: 0,
  md: 4,
  mdOffset: 2,
  lg: 4,
  lgOffset: 2,
}
const sponsorIconText = {
  xs: 12,
  xsOffset: 0,
  sm: 6,
  smOffset: 0,
  md: 4,
  mdOffset: 1,
  lg: 4,
  lgOffset: 1,
}
const content = (
  <Grid>
    <StRow>
      <Col {...sponsorIconGrid}>
        <StImage src={coseeIconPath} />
      </Col>
      <Col {...sponsorIconText}>
        <p>
          <StBold>cosee</StBold> ist unser Gastgeber und hat uns initial diese Webseite erstellt.
        </p>
      </Col>
    </StRow>
    <StRow>
      <Col {...sponsorIconGrid}>
        <StImage src={bitexpertIconPath} />
      </Col>
      <Col {...sponsorIconText}>
        <p>
          <StBold>bitExpert</StBold> verwaltet unsere Social Media-Accounts und
          hostet unsere Domain.
        </p>
      </Col>
    </StRow>
    <Row>
      <Col {...sponsorIconGrid}>
        <StImage src={dpunktIconPath} />
      </Col>
      <Col {...sponsorIconText}>
        <p>
          <StBold>dpunkt</StBold> stellt uns Bücher für unsere Verlosung zur
          Verfügung.
        </p>
      </Col>
    </Row>
  </Grid>
)
const action = null

export const CONTENT_INFOBLOCK_SPONSOR_ANCHOR = 'sponsors'
export const CONTENT_INFOBLOCK_SPONSOR = {
  title,
  content,
  action,
  theme: INFO_THEME_WHITE,
  anchorLink: CONTENT_INFOBLOCK_SPONSOR_ANCHOR,
}
