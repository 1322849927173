import React from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { withPrefix } from 'gatsby'

const twitterLogo = withPrefix('/icons/Twitter.svg')
const mailLogo = withPrefix('/icons/Mail.svg')

const FooterContainer = styled.div`
  background-color: #191919;
  color: white;
`

const IconImage = styled.img`
  width: 100%;
  max-height: 80px;
`

const CenteredRow = styled(Row)`
  justify-content: center;
`
const CenteredTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
`
const InfoBoxCol = styled(Col)`
  margin-bottom: 20px;
  hyphens: manual;
`
const VSpacer = styled.div`
  height: ${props => props.factor * 15}px;
`
const ContactLink = styled.a`
  text-decoration: none;
  color: white;
`

export default ({ data }) => {
  const iconGrid = {
    xs: 2,
    xsOffset: 0,
    sm: 2,
    smOffset: 0,
    md: 1,
    mdOffset: 0,
    lg: 1,
    lgOffset: 0,
  }

  const contactGrid = {
    xs: 9,
    xsOffset: 3,
    sm: 8,
    smOffset: 4,
    md: 3,
    mdOffset: 1,
    lg: 3,
    lgOffset: 1,
  }

  const contactGrid1 = {
    xs: 9,
    xsOffset: 3,
    sm: 8,
    smOffset: 4,
    md: 3,
    mdOffset: 1,
    lg: 3,
    lgOffset: 1,
  }

  return (
    <FooterContainer>
      <Grid fluid>
        <VSpacer factor={5} />
        <Row>
          <InfoBoxCol {...contactGrid}>
            <div>
              <strong>Kontakt:</strong>
              <br />
              <ContactLink href="mailto:info@speakonf.de">
                info@speakonf.de
              </ContactLink>
            </div>
          </InfoBoxCol>
          <InfoBoxCol {...contactGrid}>
            <strong>Veranstaltungsort:</strong>
            <br />
            cosee GmbH
            <br />
            Mina-Rees-Straße. 8
            <br />
            64295 Darmstadt
          </InfoBoxCol>
          <InfoBoxCol {...contactGrid}>
            <strong>Fotografien:</strong>
            <br />
            Alex Litvin (Unsplash)
            <br />
            Bernd Rücker
          </InfoBoxCol>
        </Row>
        <Row>
          <InfoBoxCol {...contactGrid1}>
            <strong>
              <ContactLink href="https://www.jug-da.de/impressum/">
                Impressum
              </ContactLink>
            </strong>
            <br />
            <strong>
              <ContactLink href="https://www.jug-da.de/datenschutz/">
                Daten&shy;schutz&shy;erklärung
              </ContactLink>
            </strong>
          </InfoBoxCol>
          <InfoBoxCol {...contactGrid1}>
            Website made by <br />
            <strong>&#169; cosee & JUG Darmstadt 2024</strong>
          </InfoBoxCol>
        </Row>
        <VSpacer factor={5} />
        <CenteredRow>
          <CenteredTextContainer>
            <p>
              Eine Veranstaltung des iJUG e.V., organisiert durch die JUG
              Darmstadt.
            </p>
          </CenteredTextContainer>
        </CenteredRow>
        <VSpacer factor={3} />
        <CenteredRow>
          <Col {...iconGrid}>
            <a href={'https://www.twitter.com/speakonf'}>
              <IconImage src={twitterLogo} />
            </a>
          </Col>
          <Col {...iconGrid}>
            <a href={'mailto:info@speakonf.de'}>
              <IconImage src={mailLogo} />
            </a>
          </Col>
        </CenteredRow>
        <VSpacer factor={3} />
      </Grid>
    </FooterContainer>
  )
}
